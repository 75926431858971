import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";
import { GridRowModesModelProps } from "@mui/x-data-grid/models/api/gridEditingApi";

import API from "../../../API";
import { en } from "../../../dictionary/en";
import HoC from "../../../Hoc";
import { downloadJsonFile, formatPageJson } from "../../../ReusableCodes/Resuseablefunctions";
import { buttonStyle, Spinner } from "../../../Theme/Theme";

import { EditListFormPageProps } from "./types";

const AddRow: React.FC<{
    setRows: any;
    setRowModesModel: React.Dispatch<React.SetStateAction<{ [key: string]: GridRowModes }>>;
}> = ({ setRows, setRowModesModel }) => {
    const handleClick = () => {
        const id = uuidv4();
        setRows((oldRows: any) => [
            ...oldRows,
            {
                id,
                columnName: "",
                columnValue: "",
                columnType: "",
                columnOrder: ""
            }
        ]);
        setRowModesModel((oldRowModel: any) => ({
            ...oldRowModel,
            [id]: {
                mode: GridRowModes.Edit,
                fieldToFocus: en.fieldname
            }
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<Add />} onClick={handleClick}>
                {en.addfield}
            </Button>
        </GridToolbarContainer>
    );
};

const EditListFormPage: React.FC<EditListFormPageProps> = ({ jsonDatavalue, onPropsHandleChange }) => {
    const [jsonData, setJsonData] = useState<any>(jsonDatavalue);
    console.log({ jsonData });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = React.useState<any[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<{
        [key: string]: GridRowModesModelProps;
    }>({});

    const handleRowsEditStop = (params: any, event: any) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: any) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const hanldeDeleteClick = (id: string) => () => {
        setRows((row) => row.filter((r) => r.id !== id));
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                mode: GridRowModes.View,
                ignoreModifications: true
            }
        });
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            setRows((rowsData) => rowsData.filter((row) => row.id !== id));
        }
    };

    const handleSaveClick = (id: any) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        rows.find((row) => row.id === id);
    };

    const handleRowModesModelChange = (newRowModel: { [key: string]: GridRowModesModelProps }) => {
        setRowModesModel(newRowModel);
    };

    const processRowUpdate = (newRow: any) => {
        setRows((a) => a.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const columns = [
        {
            field: en.clmname,
            headerName: en.clmheader,
            editable: true,
            preProcessEditCellProps: (params: any) => {
                const hasError = params.props.value?.length < 1;
                return { ...params.props, error: hasError };
            }
        },
        {
            field: en.clmtype,
            headerName: en.clmtypeheader,
            type: en.typeselect,
            preProcessEditCellProps: (params: any) => {
                const hasError = !params.props.value;
                return { ...params.props, error: hasError };
            },
            editable: true,
            valueOptions: [
                { label: "Text", value: "Text" },
                { label: "Radio", value: "Radio" },
                { label: "Checkbox", value: "Checkbox" },
                { label: "Toggle", value: "Toggle" },
                { label: "Select", value: "Select" },
                { label: "Date", value: "Date" }
            ]
        },
        {
            field: en.clmorder,
            headerName: en.clmorderheader,
            type: en.typenumber,
            preProcessEditCellProps: (params: any) => {
                const hasError = !params.props.value;
                return { ...params.props, error: hasError };
            },
            editable: true
        },
        {
            field: en.clmproperty,
            headerName: en.clmpropertyhead,
            valueOptions: [
                { label: "Sort by ASC", value: "ASC" },
                { label: "Sort by DSC", value: "DSC" },
                { label: "Filter", value: "FIR" }
            ],
            type: en.typeselect,
            preProcessEditCellProps: (params: any) => {
                const hasError = !params.props.value;
                return { ...params.props, error: hasError };
            },
            editable: true
        },
        {
            field: en.fieldaction,
            type: en.fieldaction,
            cellClassName: en.fieldaction,
            getActions: ({ id }: { id: string }) => {
                const isInEditMode = rowModesModel[id] === (GridRowModes as any).Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            showInMenu={false}
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            placeholder=""
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            onClick={handleCancelClick(id)}
                            showInMenu={false}
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            placeholder=""
                        />
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        onClick={handleEditClick(id)}
                        showInMenu={false}
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        placeholder=""
                    />,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={hanldeDeleteClick(id)}
                        showInMenu={false}
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        placeholder=""
                    />
                ];
            }
        }
    ];

    useEffect(() => {
        setJsonData(jsonDatavalue);
        if (jsonData?.pageDetails?.length) {
            setRows(
                jsonData?.pageDetails.map((field: any) => ({
                    columnName: field.columnName,
                    columnType: field.columnType?.label,
                    columnOrder: field.columnOrder?.label,
                    columnProperties: field.columnProperties?.label,
                    id: uuidv4()
                }))
            );
        }
    }, [jsonDatavalue]);

    useEffect(() => {
        if (rows.length) {
            rows.forEach((row) => {
                setRowModesModel({
                    ...rowModesModel,
                    [row.id]: {
                        mode: GridRowModes.View
                    }
                });
            });
        }
    }, [rows]);

    useEffect(() => {}, [rowModesModel]);

    console.log(rows);
    const handleUpdatedJson = async () => {
        try {
            const formattedJson = formatPageJson(rows, jsonData);
            downloadJsonFile({ ...formattedJson }, `${formattedJson?.pageName}.json`);
            const response: any = await API.Projects.updatePages(formattedJson);
            if (response?.success) {
                onPropsHandleChange();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Box component="div">
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <Box p={4} display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <Paper sx={{ padding: "32px" }} elevation={6}>
                        <Grid container minHeight="90vh" direction="column" justifyContent="space-around">
                            <Grid item xs={12}>
                                <Typography variant="h4">Edit {jsonData?.pageName} JSON</Typography>
                                <HoC.DataTable
                                    rows={rows}
                                    columns={columns}
                                    editMode="row"
                                    rowModesModel={rowModesModel}
                                    onRowEditStop={handleRowsEditStop}
                                    onRowModesModelChange={handleRowModesModelChange}
                                    processRowUpdate={processRowUpdate}
                                    slots={{
                                        footer: AddRow
                                    }}
                                    slotProps={{
                                        footer: {}
                                    }}
                                />
                            </Grid>

                            <Grid item display="flex" gap={2} justifyContent="space-between">
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        onPropsHandleChange();
                                    }}
                                >
                                    {en.backbtn}
                                </Button>
                                <Button
                                    style={buttonStyle}
                                    onClick={handleUpdatedJson}
                                    sx={{
                                        paddingX: 2,
                                        paddingY: 1,
                                        color: "#000"
                                    }}
                                >
                                    <Typography>{en.downloadupjson}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

export default EditListFormPage;
